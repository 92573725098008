import React, { useCallback } from 'react';
import Button from "../../../../../../../Components/Profile/Button/Button";

const ProfileBasedInterview = ({ selectedResume, setSelectedResume, startInterviewHandler }) => {
  const onDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setSelectedResume(file);
    } else {
      alert('Only PDF files are allowed.');
    }
  }, [setSelectedResume]);

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setSelectedResume(file);
    } else {
      alert('Only PDF files are allowed.');
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        className="unique-personal-interview-body"
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        {selectedResume ? (
          <p>{selectedResume.name}</p>
        ) : (
          <p>Drag and drop a PDF file here, or click to select a PDF file</p>
        )}
        <input
          type="file"
          onChange={onFileChange}
          style={{ display: 'none' }}
          id="fileUpload"
          accept="application/pdf"
        />
        <label htmlFor="fileUpload" className="unique-file-upload-label">
          Click to select a PDF file
        </label>
      </div>
      <div className="personal-interview-body-2-3">
        <Button
          text="NEXT"
          width="400px"
          disabled={!selectedResume}
          onClick={() => startInterviewHandler({resume: selectedResume})}
        />
      </div>
    </>
  );
};

export default ProfileBasedInterview;
