import React, {useState, useEffect} from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router";
import Logo from "../../../assets/surgelogo.png";

const Sidebar = ({SidebarData, renderSidebar, selectedSidebarIndex, setSelectedSidebarIndex}) => {
    const history = useNavigate();

    const handleSidebarClick = (index) => {
        renderSidebar(SidebarData[index]);
        setSelectedSidebarIndex(index);
    }

    const goToHome = () => {
        history("/skillbox-v2");
    }


    return (
        <>
            <div className="sidebar">
                <div className="sidebar-header">
                    <img src={Logo} alt="logo" className="sidebar-header-logo" onClick={goToHome}/>
                </div>
                <div className="sidebar-body">
                    <ul className="sidebar-body-list">
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index}  className={index === selectedSidebarIndex ? "sidebar-body-list-item active" : "sidebar-body-list-item"} onClick={() => handleSidebarClick(index)}>
                                    <div className="sidebar-body-list-item-icon">{item.icon && item.icon}</div>
                                    <div className="sidebar-body-list-item-title">{item.title}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;