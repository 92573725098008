import React, { useEffect } from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./AddContent.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
import fileToBase64 from "../../../../utilities/base64";
import fileToZip from "../../../../utilities/fileToZip";
const swal = require("sweetalert2");

const AddContent = ({ classItem, showPopup, setShowPopup, onAdd }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialContentState = {
    content_name: "",
    content_summary: "",
    content_lock: true,
    content_type: "video",
    content_file: null,
    content_file_url: null,
    content_order: null,
  };

  const [contentData, setContentData] = useState(initialContentState);

  const [validationErrors, setValidationErrors] = useState({
    content_name: false,
    content_summary: false,
    content_type: false,
    content_file: false,
    content_file_url: false,
    content_order: false,
  });

  const convertFileToZip = async (file) => {
    try {
        const extension = file.name.split(".").pop();

        if (!["zip", "pdf", "png", "mp4", "jpeg", "jpg"].includes(extension)) {
            const zipFile = await fileToZip(file);
            const base64 = await fileToBase64(zipFile);
            return base64;
        } else {
            const base64 = await fileToBase64(file);
            return base64;
        }
    } catch (error) {
        swal.fire({
            title: "Error!",
            text: error || "Something went wrong, please try again later.",
            icon: "error",
            confirmButtonText: "Close",
        });
    }
};


  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setContentData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleFileChange = async(file) => {
    const base64 = await convertFileToZip(file);
    setContentData((prev) => {
      return {
        ...prev,
        content_file: base64,
      };
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      content_file: false,
    }));
  };

  const validateForm = () => {
    const requiredFields = [
      "content_name",
      "content_summary",
      "content_type",
      "content_file",
      "content_file_url",
      "content_order",
    ];

    const newValidationErrors = {};

    const checkFile = () => {
      if ((contentData["content_file"] && contentData["content_file"].length > 0) || (contentData["content_file_url"] && contentData["content_file_url"].length > 0)) {
        return true;
      }
      return false;
    };

    for (const field of requiredFields) {
      if (field === "content_file" || field === "content_file_url") {
        if (!checkFile()) {
          newValidationErrors[field] = true;
        } else {
          delete newValidationErrors["content_file_url"];
          delete newValidationErrors["content_file"];
        }
      } else if (
        !contentData[field] ||
        contentData[field].trim().length === 0
      ) {
        newValidationErrors[field] = true;
      } else {
        delete newValidationErrors[field];
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      ...newValidationErrors,
    }));

    return Object.keys(newValidationErrors).length === 0;
  };

  const resetForm = () => {
    setContentData(initialContentState);
    setValidationErrors({
      content_name: false,
      content_summary: false,
      content_type: false,
      content_file: false,
      content_file_url: false,
      content_order: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/content/${classItem.class_id}`,
        "POST",
        JSON.stringify(contentData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onAdd(response.content);
        swal.fire({
          title: "Content Added Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error Occured",
        text: err.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }, [error, clearError, setShowPopup]);

  return (
    <PopUp
      title="Add Content"
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="add_content_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="add_content_popup__content">
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Content Name</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Content Name"
                  name="content_name"
                  value={contentData.content_name}
                  onChange={handlechange}
                  className={
                    validationErrors.content_name ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_name && (
                  <small className="input_val_error-text">
                    Please enter a content name.
                  </small>
                )}
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Content Summary</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Content Summary"
                  name="content_summary"
                  value={contentData.content_summary}
                  onChange={handlechange}
                  className={
                    validationErrors.content_summary ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_summary && (
                  <small className="input_val_error-text">
                    Please enter a content summary.
                  </small>
                )}
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Is Active</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <select
                  name="content_type"
                  value={contentData.content_type}
                  onChange={handlechange}
                >
                  <option value="true">Live</option>
                  <option value="false">Not Live</option>
                </select>
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Content Order</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <input
                  type="number"
                  placeholder="Enter Content Order"
                  name="content_order"
                  value={contentData.content_order}
                  onChange={handlechange}
                  className={
                    validationErrors.content_order ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_order && (
                  <small className="input_val_error-text">
                    Please enter a content order.s
                  </small>
                )}
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Content Type</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <select
                  name="content_type"
                  value={contentData.content_type}
                  onChange={handlechange}
                >
                  <option value="video">Video</option>
                  <option value="document">Document</option>
                </select>
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Upload File</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <input
                  type="file"
                  name="content_file"
                  accept="*"
                  multiple={false}
                  onChange={(e) => handleFileChange(e.target.files[0])}
                  className={
                    validationErrors.content_file ? "input_val_error" : ""
                  }
                />
              </div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">or</div>
            </div>
            <div className="add_content_popup__content-row">
              <div className="add_content_popup__content-row-title">
                <p>Content File URL</p>
              </div>
              <div className="add_content_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter File URL"
                  name="content_file_url"
                  value={contentData.content_file_url}
                  onChange={handlechange}
                />
                {(validationErrors.content_file_url ||
                  validationErrors.content_file) && (
                  <small className="input_val_error-text">
                    Please enter a file url or upload a file.
                  </small>
                )}
              </div>
            </div>
            <div className="add_content_popup__buttons">
              <button
                className="add_content_popup__buttons-cancel"
                type="button"
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                Cancel
              </button>
              <button className="add_content_popup__buttons-save" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
      )}
    </PopUp>
  );
};
export default AddContent;
