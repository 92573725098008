import React, { useRef, useEffect, useState, useContext } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { FiPlayCircle } from "react-icons/fi";  

import "./VideoPlayer.css";
import DataScienceImg from "../../assets/Continuing-professional-development-1170x630.jpg";

const VideoPlayer = (props) => {
  const history = useNavigate();
  const playerRef = useRef();
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [videoData, setVideoData] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  let courseTitle = props.courseId;
  const url = props.url.startsWith("http://") || props.url.startsWith("https://") ? props.url : `${process.env.REACT_APP_BACKEND_URL}${props.url}`;


  const updateVideoInfo = () => {
    let currentTime = playerRef.current.getCurrentTime();
    if (currentTime > 0) {
      let videoDataObj = [];
      if (localStorage.getItem("videoData")) {
        videoDataObj = JSON.parse(localStorage.getItem("videoData"));
      } else {
        videoDataObj = videoData;
      }

      let current_video = videoDataObj.find(
        (video) => video.video_link === url
      );

      if (current_video) {
        current_video.duration_watched = currentTime;
        current_video.start_time = startTime;
        videoDataObj = videoDataObj.filter(
          (video) => video.video_link !== url
        );
      } else {
        current_video = {};
        current_video["video_link"] = url; 
        current_video["video_duration"] = playerRef.current.getDuration();
        current_video["duration_watched"] = currentTime;
        current_video["start_time"] = startTime;
      }
      videoDataObj.push(current_video);
      setVideoData(videoDataObj);
      localStorage.setItem("videoData", JSON.stringify(videoDataObj));
      updateVideoData(current_video);
    }
  };

  const getVideoData = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/video-data/${courseTitle}`,
        "get",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
      if (responseData && responseData.data) {
        let video_data = responseData.data;
        if (typeof video_data == "string") {
          video_data = JSON.parse(video_data);
        }
        if (localStorage.getItem("videoData")) {
          let local_video_data = JSON.parse(localStorage.getItem("videoData"));
          video_data = video_data.concat(local_video_data);
        }
        setVideoData(video_data);
        localStorage.setItem("videoData", JSON.stringify(video_data));
        return responseData.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateVideoData = async (current_video) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/${courseTitle}/video`,
        "post",
        JSON.stringify({
          video_data: current_video,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleVideoPlayPause = (play = true) => {
    if (play) {
      let video_data = [];
      if (localStorage.getItem("videoData")) {
        video_data = JSON.parse(localStorage.getItem("videoData"));
        if (typeof video_data == "string") {
          video_data = JSON.parse(video_data);
        }
        let current_video = video_data.find(
          (video) => video.video_link === url
        );
        if (current_video) {
          playerRef.current.seekTo(current_video.duration_watched);
          setStartTime(current_video.duration_watched);
        }
      } else {
        video_data = videoData;
      }
    } else {
      updateVideoInfo();
    }
    setIsPlaying(play);
  };

  useEffect(() => {
    const apiCall = async () => {
      await getVideoData();
    };
    if (localStorage.getItem("videoData")) {
      setVideoData(JSON.parse(localStorage.getItem("videoData")));
    }
    apiCall();
  }, []);

  return (
    <div className="video video-custom">
      <div className="video-box video-box-custom">
        <div
          className="module-flex2 module-flex2-custom"
          onMouseDown={(e) => e.preventDefault()}
          onContextMenu={(e) => e.preventDefault()}
        >
          <div className="video-player-div video-player-div-custom">
            <ReactPlayer
              className="react-player react-player-custom"
              ref={playerRef}
              posterimage={DataScienceImg}
              url = {url}
              thumbnail={DataScienceImg}
              width="100%"
            //   height="470px"
              loop={false}
              controls
              onPlay={() => {
                handleVideoPlayPause();
              }}
              onPause={() => {
                handleVideoPlayPause(false);
              }}
              onEnded={() => {
                setIsPlaying(false);
              }}              
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              playing={true}
            ></ReactPlayer>
            {!isPlaying ? (
                <div className="play-button-container">
                <div
                  className="play-button"
                  onClick={() => handleVideoPlayPause(true)}
                >
                    <FiPlayCircle />
                </div>
              </div>
                ) : null}

          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
