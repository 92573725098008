import React from 'react';
import Select from "../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../Components/Profile/Button/Button";

const CommunicationBasedInterview = ({ level, setSelectedLevel, selectedLevel, startInterviewHandler }) => {
  return (
    <>
      {level.length > 0 && (
        <>
          <div className="personal-interview-body-2-2">
            <Select
              isDisabled={false}
              selectData={level}
              width="300px"
              onChange={(data) => setSelectedLevel(data.value)}
              selectLabel={true}
              selectLabelText="Select Level"
            />
          </div>
          <div className="personal-interview-body-2-3">
            <Button
              text="NEXT"
              width="400px"
              disabled={selectedLevel === ""}
              onClick={() => startInterviewHandler({level: selectedLevel})}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CommunicationBasedInterview;
