import React from 'react';
import "./ProfileBasedInterview.css";
import Select from "../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../Components/Profile/Button/Button";

const PlacementPrepInterview = ({ jobpackage, setSelectedPackage, selectedPackage, startInterviewHandler }) => {
  return (
    <>
      {jobpackage.length > 0 && (
        <>
          <div className="personal-interview-body-2-2">
            <Select
              isDisabled={false}
              selectData={jobpackage}
              width="300px"
              onChange={(data) => setSelectedPackage(data.value)}
              selectLabel={true}
              selectLabelText="Select Package"
            />
          </div>
          <div className="personal-interview-body-2-3">
            <Button
              text="NEXT"
              width="400px"
              disabled={selectedPackage === ""}
              onClick={() => startInterviewHandler({companypackage: selectedPackage})}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PlacementPrepInterview;
