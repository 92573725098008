import React from 'react';
import './InterviewType.css';
import rolebased from './Assets/rolebased.png';
import skillbased from './Assets/skillbased.png';
import profilebased from './Assets/profilebased.png';
import communicationbased from './Assets/communicationbased.png';
import placementbased from './Assets/placementprep.png';
import commcover from "../../../assets/commcover.png";
import placementcov from "../../../assets/placementcov.png";
import profilecover from "../../../assets/profilecover.png";
import rolecover from "../../../assets/rolecover.png";
import skillcover from "../../../assets/skillcover.png";
import Button from '../../../../../../Components/Profile/Button/Button';
import InterviewStart from '../PersonalInterview/Pages/InterviewStart';

const InterviewTypeCard = ({ interview, handleClick }) => {
    return (
        <div className="st-skillbox-interview-type-card" key={interview.type_slug} onClick={handleClick}>
            <div className='st-skillbox-interview-type-card__img'>
                <img src={interview.image} alt={interview.title} />
            </div>
            <div className="st-skillbox-interview-type-card__title">
                {interview.title}
            </div>
        </div>
    );
}

const interViewTypeList = [
    {
        type_slug: "roleBasedInterview",
        title: "Role Based Interview",
        cover: rolecover,
        image: rolebased,
        keyPoints: [
            "AI-powered feedback on communications",
            "Analyze your performance and identify areas for growth",
            "Comprehensive analytics panel",
            "Improved student-teacher engagement",
        ],
        InterviewStartTitle: "Choose a role you want to interview for",
    },
    {
        type_slug: "skillBasedInterview",
        title: "Skill Based Interview",
        cover: skillcover,
        image: skillbased,
        keyPoints: [
            "Analyze your responses and pinpoint areas for improvement",
            "Evaluation of speech and content",
            "Receive a detailed performance report to track your progress",
            "Get customized tips to enhance your body language and tone",
        ],
        InterviewStartTitle: "Choose a skill you want to interview for",
    },
    {
        type_slug: "profileBasedInterview",
        title: "Profile Based Interview",
        cover: profilecover,  
        image: profilebased,
        keyPoints: [
          "Practice answering a range of common and behavioral interview questions",
          "Real-time feedback on your responses, body language, and tone",
          "Customized tips to enhance your communication and presentation skills",
          "Detailed performance reports to track your progress and identify areas for growth"
        ],  
        InterviewStartTitle: "Upload your resume to get started",
    },
    {
        type_slug: "communicationBasedInterview",
        title: "Communication Interview",
        cover: commcover,
        image: communicationbased,
        keyPoints: [
            "AI-powered feedback on communications and speech",
            "Receive tips to improve your body language and tone",
            "Practice answering tough behavioral questions",
            "Improved student-teacher engagement",
        ],
        InterviewStartTitle: "Choose a communication level you want to improve",
    },
    {
        type_slug: "placementBasedInterview",
        title: "Placement Preparation",
        cover: placementcov,
        image: placementbased,
        keyPoints: [
          "AI-powered feedback on communications, speech and content",
          "Get personalized feedback on your responses",
          "Comprehensive analytics panel",
          "Improved student-teacher engagement",
        ],
        InterviewStartTitle: "Choose a salary package you want to prepare for"
    }
  ];
  

const InterviewType = ({
    setSelectedTab,
    setSelectedOption,
    setSelectedInterviewType
}) => {

    const goToHome = () => {
        setSelectedOption({
            value: "skillbox",
            label: "Skillbox",
        });
    };


    const handleInterviewData = (data) => {
        setSelectedInterviewType(data);
        setSelectedTab("personalInterview");
    }


    return (
        <>
            <div className="personal-interview-page-nav-menu">
                <div
                    onClick={goToHome}
                    className="personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
            </div>
            <div className="profile-user-resume-header">
                <div className="profile-user-resume-header-title">
                    Select Type of Interview
                </div>
                <Button
                    text="Previous Interview Feedback"
                    width="230px"
                    onClick={() => setSelectedTab("interviewFeedback")}
                />
            </div>
            <div className="st-skillbox-programs-mn-ct">
                {interViewTypeList && interViewTypeList.length > 0 && interViewTypeList.map((p, i) => (
                    <div key={i}>
                        <InterviewTypeCard interview={p} handleClick={() => handleInterviewData(p)} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default InterviewType;
