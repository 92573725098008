import React from 'react';
import Select from "../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../Components/Profile/Button/Button";

const SkillBasedInterview = ({ skills, level, setSelectedRole, setSelectedLevel, selectedSkill, selectedLevel, startInterviewHandler }) => {
  return (
    <>
      {skills.length > 0 && level.length > 0 && (
        <>
          <div className="personal-interview-body-2-2">
            <Select
              isDisabled={false}
              selectData={skills}
              width="300px"
              onChange={(data) => setSelectedRole(data.value)}
              selectLabel={true}
              selectLabelText="Select Skill"
            />
            <Select
              isDisabled={false}
              selectData={level}
              width="300px"
              onChange={(data) => setSelectedLevel(data.value)}
              selectLabel={true}
              selectLabelText="Select Level"
            />
          </div>
          <div className="personal-interview-body-2-3">
            <Button
              text="NEXT"
              width="400px"
              disabled={selectedSkill === "" || selectedLevel === ""}
              onClick={() => startInterviewHandler({ skill: selectedSkill, level:selectedLevel })}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SkillBasedInterview;
