import React from 'react';
import Select from "../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../Components/Loading";

const RoleBasedInterview = ({ isLoading, error, roles, level, setSelectedRole, setSelectedLevel, selectedRole, selectedLevel, startInterviewHandler }) => {
  return (
    <>
      {isLoading && (
        <div className="personal-interview-body-2-2">
          <Loading color="#004c73" />
        </div>
      )}
      {error && (
        <div className="personal-interview-body-2-2">
          <div className="personal-interview-error">
            Something went wrong. Please try again later.
          </div>
        </div>
      )}
      {roles.length > 0 && level.length > 0 && (
        <>
          <div className="personal-interview-body-2-2">
            <Select
              isDisabled={false}
              selectData={roles}
              width="300px"
              onChange={(data) => setSelectedRole(data.value)}
              selectLabel={true}
              selectLabelText="Select Role"
            />
            <Select
              isDisabled={false}
              selectData={level}
              width="300px"
              onChange={(data) => setSelectedLevel(data.value)}
              selectLabel={true}
              selectLabelText="Select Level"
            />
          </div>
          <div className="personal-interview-body-2-3">
            <Button
              text="NEXT"
              width="400px"
              disabled={selectedRole === "" || selectedLevel === ""}
              onClick={() => startInterviewHandler({role : selectedRole, joblevel : selectedLevel})}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RoleBasedInterview;
