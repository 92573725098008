import React from "react";
import { useState, useEffect, useContext } from "react";
import "./InterviewPreperation.css";
import PersonalInterview from "./PersonalInterview/PersonalInterview";
import InterviewFeedback from "./InterviewFeedback/InterviewFeedback";
import InterviewType from "./InterviewType/InterviewType";

const InterviewPreperation = ({ setSelectedOption }) => {
  const [selectedTab, setSelectedTab] = useState("interviewHome");
  const [selectedInterviewType, setSelectedInterviewType] = useState({
    type_slug: "",
    title: "",
    cover: null,
    image: null,
    keyPoints: [],
  });

  const renderSelectedTab = () => {
    if (selectedTab === "interviewHome") {
      return (
        <InterviewType
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          setSelectedInterviewType={setSelectedInterviewType}
        />
      );
    }
    else if (selectedTab === "personalInterview") {
      return (
        <PersonalInterview
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          selectedInterviewType={selectedInterviewType}
        />
      );
    } else if (selectedTab === "interviewFeedback") {
      return (
        <InterviewFeedback
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
        />
      );
    }
  };

  return <div>{renderSelectedTab()}</div>;
};
export default InterviewPreperation;
