import React from "react";
import UnderDevelopment from "../Construction/UnderDevelopment";
import InterviewPreperation from "../Layout/InterviewPreperation/InterviewPreperation";
import Courses from "../Layout/Courses/Courses";

export const SkillboxSideBarData = [
  {
    title: "Enrolled Courses",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6562 10.5C21.9844 10.5 22.2891 10.5586 22.5703 10.6758C22.8516 10.793 23.1016 10.957 23.3203 11.168C23.5391 11.3789 23.7031 11.625 23.8125 11.9062C23.9219 12.1875 23.9844 12.4922 24 12.8203C24 13.125 23.9414 13.4219 23.8242 13.7109C23.707 14 23.5391 14.2539 23.3203 14.4727L14.918 22.8984L10.5 24L11.6016 19.582L20.0039 11.168C20.2227 10.9492 20.4766 10.7852 20.7656 10.6758C21.0547 10.5664 21.3516 10.5078 21.6562 10.5ZM22.2539 13.418C22.418 13.2539 22.5 13.0547 22.5 12.8203C22.5 12.5781 22.4219 12.3828 22.2656 12.2344C22.1094 12.0859 21.9062 12.0078 21.6562 12C21.5469 12 21.4414 12.0156 21.3398 12.0469C21.2383 12.0781 21.1484 12.1367 21.0703 12.2227L12.9609 20.3555L12.5625 21.9375L14.1445 21.5391L22.2539 13.418ZM7.5 10.5H6V9H7.5V10.5ZM18 10.5H9V9H18V10.5ZM6 13.5H7.5V15H6V13.5ZM7.5 6H6V4.5H7.5V6ZM18 6H9V4.5H18V6ZM4.5 19.5H9.69141L9.31641 21H3V0H21V8.70703C20.4766 8.78516 19.9766 8.95703 19.5 9.22266V1.5H4.5V19.5ZM9 13.5H15.0234L13.5234 15H9V13.5Z"
          fill="#434343"
        />
      </svg>
    ),
    component: Courses
  },
  {
    title: "My Doubts",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3C10.8065 3 9.66193 3.47411 8.81802 4.31802C7.97411 5.16193 7.5 6.30653 7.5 7.5C7.5 7.69891 7.57902 7.88968 7.71967 8.03033C7.86032 8.17098 8.05109 8.25 8.25 8.25C8.44891 8.25 8.63968 8.17098 8.78033 8.03033C8.92098 7.88968 9 7.69891 9 7.5C9 6.70435 9.31607 5.94129 9.87868 5.37868C10.4413 4.81607 11.2044 4.5 12 4.5C12.7956 4.5 13.5587 4.81607 14.1213 5.37868C14.6839 5.94129 15 6.70435 15 7.5C15 8.514 14.79 9.12 14.5275 9.54C14.253 9.9795 13.8855 10.281 13.407 10.665L13.377 10.6875C12.924 11.0505 12.369 11.493 11.9505 12.165C11.5095 12.87 11.25 13.764 11.25 15V15.75C11.25 15.9489 11.329 16.1397 11.4697 16.2803C11.6103 16.421 11.8011 16.5 12 16.5C12.1989 16.5 12.3897 16.421 12.5303 16.2803C12.671 16.1397 12.75 15.9489 12.75 15.75V15C12.75 13.986 12.96 13.38 13.2225 12.96C13.497 12.5205 13.8645 12.219 14.343 11.835L14.373 11.8125C14.826 11.4495 15.381 11.007 15.7995 10.335C16.2405 9.63 16.5 8.736 16.5 7.5C16.5 6.30653 16.0259 5.16193 15.182 4.31802C14.3381 3.47411 13.1935 3 12 3ZM12 21C12.2984 21 12.5845 20.8815 12.7955 20.6705C13.0065 20.4595 13.125 20.1734 13.125 19.875C13.125 19.5766 13.0065 19.2905 12.7955 19.0795C12.5845 18.8685 12.2984 18.75 12 18.75C11.7016 18.75 11.4155 18.8685 11.2045 19.0795C10.9935 19.2905 10.875 19.5766 10.875 19.875C10.875 20.1734 10.9935 20.4595 11.2045 20.6705C11.4155 20.8815 11.7016 21 12 21Z"
          fill="#434343"
        />
      </svg>
    ),
    component: UnderDevelopment
  },
  {
    title: "Interview",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 15.85C7.32761 15.85 7.16228 15.9185 7.04038 16.0404C6.91848 16.1623 6.85 16.3276 6.85 16.5C6.85 16.6724 6.91848 16.8377 7.04038 16.9596C7.16228 17.0815 7.32761 17.15 7.5 17.15H16.5C16.6724 17.15 16.8377 17.0815 16.9596 16.9596C17.0815 16.8377 17.15 16.6724 17.15 16.5C17.15 16.3276 17.0815 16.1623 16.9596 16.0404C16.8377 15.9185 16.6724 15.85 16.5 15.85H7.5ZM7.04038 18.0404C6.91848 18.1623 6.85 18.3276 6.85 18.5C6.85 18.6724 6.91848 18.8377 7.04038 18.9596C7.16228 19.0815 7.32761 19.15 7.5 19.15H13.5C13.6724 19.15 13.8377 19.0815 13.9596 18.9596C14.0815 18.8377 14.15 18.6724 14.15 18.5C14.15 18.3276 14.0815 18.1623 13.9596 18.0404C13.8377 17.9185 13.6724 17.85 13.5 17.85H7.5C7.32761 17.85 7.16228 17.9185 7.04038 18.0404Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M10.6 7.74988L10.6 7.7499L12.4707 12.2392C12.4741 12.247 12.4773 12.255 12.4804 12.263L13.1 13.7499C13.1663 13.909 13.1668 14.0879 13.1012 14.2474C13.0356 14.4068 12.9094 14.5337 12.7503 14.6C12.5912 14.6664 12.4122 14.6669 12.2528 14.6013C12.0933 14.5357 11.9665 14.4095 11.9001 14.2503L11.9001 14.2503L11.4415 13.1501H8.55853L8.1 14.2503L8.09998 14.2503C8.03361 14.4095 7.90675 14.5357 7.74731 14.6013C7.58787 14.6669 7.40891 14.6664 7.24979 14.6C7.09068 14.5337 6.96445 14.4068 6.89887 14.2474C6.83329 14.0879 6.83373 13.909 6.9001 13.7499M10.6 7.74988L7.03854 13.8076M10.6 7.74988C10.5506 7.63146 10.4673 7.53029 10.3605 7.45913C10.2538 7.38797 10.1283 7.35 10 7.35C9.87173 7.35 9.7463 7.38797 9.63954 7.45913C9.53278 7.53029 9.44946 7.63146 9.40009 7.74988L9.40008 7.7499L7.52939 12.2392M10.6 7.74988L7.52939 12.2392M6.9001 13.7499L7.03854 13.8076M6.9001 13.7499L6.90008 13.7499L7.03854 13.8076M6.9001 13.7499L7.51971 12.263M7.03854 13.8076L7.65904 12.3186M7.51971 12.263C7.51941 12.2638 7.5191 12.2646 7.5188 12.2654L7.65904 12.3186M7.51971 12.263C7.52278 12.255 7.52601 12.247 7.52939 12.2392M7.51971 12.263L7.52058 12.2609L7.65904 12.3186M7.65904 12.3186L7.52939 12.2392M9.10004 11.8501L10 9.6901L10.9 11.8501H9.10004Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M15.4596 10.5404C15.3377 10.4185 15.1724 10.35 15 10.35C14.8276 10.35 14.6623 10.4185 14.5404 10.5404C14.4185 10.6623 14.35 10.8276 14.35 11V11.85H13.5C13.3276 11.85 13.1623 11.9185 13.0404 12.0404C12.9185 12.1623 12.85 12.3276 12.85 12.5C12.85 12.6724 12.9185 12.8377 13.0404 12.9596C13.1623 13.0815 13.3276 13.15 13.5 13.15H14.35V14C14.35 14.1724 14.4185 14.3377 14.5404 14.4596C14.6623 14.5815 14.8276 14.65 15 14.65C15.1724 14.65 15.3377 14.5815 15.4596 14.4596C15.5815 14.3377 15.65 14.1724 15.65 14V13.15H16.5C16.6724 13.15 16.8377 13.0815 16.9596 12.9596C17.0815 12.8377 17.15 12.6724 17.15 12.5C17.15 12.3276 17.0815 12.1623 16.9596 12.0404C16.8377 11.9185 16.6724 11.85 16.5 11.85H15.65V11C15.65 10.8276 15.5815 10.6623 15.4596 10.5404Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M19.15 7.5V7.44201L19.111 7.3991L14.111 1.8991L14.0664 1.85H14H7C6.42978 1.85 5.88292 2.07652 5.47972 2.47972C5.07652 2.88292 4.85 3.42978 4.85 4V20C4.85 20.5702 5.07652 21.1171 5.47972 21.5203C5.88292 21.9235 6.42978 22.15 7 22.15H17C17.5702 22.15 18.1171 21.9235 18.5203 21.5203C18.9235 21.1171 19.15 20.5702 19.15 20V7.5ZM13.35 7.5C13.35 7.67239 13.4185 7.83772 13.5404 7.95962C13.6623 8.08152 13.8276 8.15 14 8.15H17.85V20C17.85 20.2254 17.7604 20.4416 17.601 20.601C17.4416 20.7604 17.2254 20.85 17 20.85H7C6.77457 20.85 6.55837 20.7604 6.39896 20.601C6.23955 20.4416 6.15 20.2254 6.15 20V4C6.15 3.77457 6.23955 3.55837 6.39896 3.39896C6.55837 3.23955 6.77457 3.15 7 3.15H13.35V7.5ZM14.65 6.85V4.4245L16.8549 6.85H14.65Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
      </svg>
    ),
    component: InterviewPreperation
  },
  {
    title: "View Jobs",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6C20.58 6 21.05 6.2 21.42 6.59C21.8 7 22 7.45 22 8V19C22 19.55 21.8 20 21.42 20.41C21.05 20.8 20.58 21 20 21H4C3.42 21 2.95 20.8 2.58 20.41C2.2 20 2 19.55 2 19V8C2 7.45 2.2 7 2.58 6.59C2.95 6.2 3.42 6 4 6H8V4C8 3.42 8.2 2.95 8.58 2.58C8.95 2.2 9.42 2 10 2H14C14.58 2 15.05 2.2 15.42 2.58C15.8 2.95 16 3.42 16 4V6H20ZM4 8V19H20V8H4ZM14 6V4H10V6H14Z"
          fill="#434343"
        />
      </svg>
    ),
    component: UnderDevelopment
  },
];
